import React from "react";
import Helmet from "react-helmet";

import Layout from "components/Layout";
import Container from "components/Container";

const IndexPage = () => {
  return (
    <Layout pageName="home">
      <Helmet>
        <title>Solilux e.V.</title>
      </Helmet>
      <Container>
        <div className="content">
          <h1>Solilux e.V.</h1>
          <p>gemeinnützig</p>
          <p>Solilux setzt sich für die Förderung von Kunst, Kultur und Bildung insbesondere in strukturschwachen Regionen u.a. in Thüringen, Sachsen und Brandenburg ein.</p>
          <p>Solilux initiiert und organisiert unentgeltliche öffentliche Veranstaltungen und kulturelle Ereignisse. Wir arbeiten dabei mit Partner-Projekten zusammen, die sich zum Teil seit Jahrzehnten vor Ort für zivilgesellschaftliche Strukturen mit progressiven gesellschaftlichen Vorstellungen, für ein offenes und demokratisches Zusammenleben engagieren.</p>
    <p>Gemeinsam organisieren wir Konzerte, Aufführungen, Workshops, Vorträge und Theatervorstellungen. Dabei sind uns interkulturelle Themen, Themen der Gleichstellung, der Mädchenförderung wie auch generationenübergreifendes Zusammenarbeiten wichtig.</p>
<p>Bankverbindung: Solilux e.V.</p>
<p>IBAN: DE11 8306 5408 0004 2207 06</p>
<p>BIC: GENO DEF1 SLR</p>  
<p>Deutsche Skatbank</p>     
    <p><a href="mailto:kontakt@solilux.net">kontakt@solilux.net</a></p>
        </div>
      </Container>
    </Layout>
  );
};

export default IndexPage;
